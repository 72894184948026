// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "../css/base.scss"
import "../css/tailwind.scss"
import "../css/components/base.scss"
import "../css/components/input.scss"
import "../css/components/btn.scss"
import "../css/components/header.scss"
import "../css/components/my.scss"
import "../css/components/registration-form.scss"
import "../css/components/stripe-element.scss"
import "../css/components/list-cards.scss"
import "../css/components/file-form.scss"
import "../css/components/card-table.scss"
import "../css/components/session-page.scss"
import "../css/components/pagination.scss"
import "../css/components/table.scss"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
